import React, { useState, useEffect } from "react";
import { useHistory, withRouter, Link } from "react-router-dom"
import { post, del } from '../../helpers/api_helper';
import { POST_USERS_LIST, DELETE_USERS, POST_UPDATE_USERS_STATUS, POST_GET_USERS_DETAIL } from '../../helpers/url_helper';
import { Helmet } from "react-helmet";
import { getToken, uct_local } from '../../helpers/Helper';
import { toast } from 'react-toastify';
import ReactDOM from 'react-dom';

import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";


const UserList = props => {

    let history = useHistory();
    const [user_id, SetUserId] = useState(0);
    const [userInfo, SetuserInfo] = useState({});
    const [Load, IsLoad] = useState(false);

    useEffect(() => {
        get_list();
    }, [])

    // get list
    const get_list = () => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": POST_USERS_LIST,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [6, 7] }, { "targets": 0, "visible": false }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                        "error": function (xhr, textStatus, errorThrown) {
                            if (xhr.status === 401) {
                                window.location.href = '/logout';
                            }
                        }
                    });
                },
                "columns": [
                    { "data": "userId" },
                    { "data": "userName" },
                    { "data": "mobileNumber" },
                    { "data": "companyName" },
                    { "data": "gstNumber" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {uct_local(data.createdAt)}
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {data.accessAddParty === 'Yes' && <button onClick={UpdateStatus.bind(this, data.userId, data.accessAddParty,'access')} class="border-0 badge badge-success pt-1 pb-1">Yes</button>}
                                {data.accessAddParty === 'No' && <button onClick={UpdateStatus.bind(this, data.userId, data.accessAddParty,'access')} class="border-0 badge badge-danger pt-1 pb-1" >No</button>}
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {data.userStatus === 'Pending' && <button onClick={UpdateStatus.bind(this, data.userId, data.userStatus,'status')} class="border-0 badge badge-info pt-1 pb-1"  >Approve</button>}
                                {data.userStatus === 'Active' && <button onClick={UpdateStatus.bind(this, data.userId, data.userStatus,'status')} class="border-0 badge badge-success pt-1 pb-1"  >Active</button>}
                                {data.userStatus === 'Deactive' && <button onClick={UpdateStatus.bind(this, data.userId, data.userStatus,'status')} class="border-0 badge badge-danger pt-1 pb-1" >Deactive</button>}
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                <button class="btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1  mr-2" onClick={() => { getUserInfo(data.userId); }} data-toggle="modal" data-target="#viewModal"><i class="fa fa-eye" style={{ 'font-size': '12px' }} aria-hidden="true"></i></button>
                                <button class="btn btn-sm btn-danger pt-0 pb-0 pl-1 pr-1  mr-2" onClick={() => { SetUserId(data.userId); }} data-toggle="modal" data-target="#deleteModal" ><i class="fa fa-trash-o" style={{ 'font-size': '12px' }} aria-hidden="true"></i></button>
                                <button class="btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1  mr-2" onClick={() => { history.push('/users/edit/' + data.userId); }} ><i class="fa fa-key" style={{'font-size': '12px'}} aria-hidden="true"></i></button>
                            </>, td)
                        }
                    },
                ]
            });
        });
    }

    // remove user role
    const remove_row = async () => {
        var res_data = await del(DELETE_USERS + "?userId=" + user_id);
        if (res_data.status) {
            toast(res_data.message);
            get_list();
        } else {
            toast.error(res_data.message);
        }
    }

    //UpdateStatus
    const UpdateStatus = async (userId, status,type = '') => {
        var res_data = await post(POST_UPDATE_USERS_STATUS, { userId: userId, status: status, type : type });
        if (res_data.status) {
            toast(res_data.message);
            get_list();
        } else {
            toast.error(res_data.message);
        }
    }

    const getUserInfo = async (userId) => {
        IsLoad(true);
        var res_data = await post(POST_GET_USERS_DETAIL, { userId: userId });
        if (res_data.status) {
            SetuserInfo(res_data.data.user_info);
        } else {
            toast.error(res_data.message);
        }
        IsLoad(false);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Admin | Users List</title>
            </Helmet>

            <div className='row mt-3'>

                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h6 className='mt-2 mb-0'>Users List</h6>
                                </div>
                                <div className="col-sm-6 text-right">
                                    {/* <Link to="users/add" className="btn btn-sm btn-primary pt-1 pb-1 pl-3 pr-3 c-btn-primary text-white">+ Add</Link> */}
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className="mt-2 mb-5">
                                <table id="dataTableExample" className="table table-striped ">
                                    <thead>
                                        <tr>
                                            <td>Id</td>
                                            <td>User Name</td>
                                            <td>Mobile Number</td>
                                            <td>Company Name</td>
                                            <td>Gst Number</td>
                                            <td>Reg Date</td>
                                            <td>Add Party</td>
                                            <td>Status</td>
                                            <td>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Confirm Delete</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to delete this record?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-sm btn-dnager c-btn-danger text-white" data-dismiss="modal">No</button>
                                <button onClick={remove_row.bind(this)} data-dismiss="modal" className="btn btn-sm btn-primary c-btn-primary">Yes</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="viewModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title m-0" id="exampleModalLabel">View Detail</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {Load && <div>Loading...</div>}
                                {userInfo && <>
                                    <div className="row">
                                        <div className="col-sm-4 mb-2">
                                            {userInfo.companyLogo && <img src={userInfo.companyLogo} style={{ 'maxWidth': '100px', 'maxHeight': '100px' }} />}
                                        </div>
                                        <div className="col-sm-4 mb-2">
                                            <p className="mb-0"><b>User Name</b></p>
                                            <p className="mb-2">{userInfo.userName}</p>
                                            <p className="mb-0"><b>Mobile Number</b></p>
                                            <p className="mb-0">{userInfo.mobileNumber}</p>
                                        </div>
                                        <div className="col-sm-4 mb-2">
                                            <p className="mb-0"><b>Company Name</b></p>
                                            <p className="mb-2">{userInfo.companyName}</p>
                                            <p className="mb-0"><b>GST Number</b></p>
                                            <p className="mb-0">{userInfo.gstNumber}</p>
                                        </div>
                                        <div className="col-sm-4 mb-2">
                                            <p className="mb-0"><b>address</b></p>
                                            <p className="mb-0">{userInfo.address}</p>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-sm-12">
                                            <div className="table-responsive">
                                                <table className="table table-sm">
                                                    <thead>
                                                        <tr>
                                                            <td>Company Name</td>
                                                            <td>GST Number</td>
                                                            <td>Party Name</td>
                                                            <td>Mobile Number</td>
                                                            <td>Agent Name</td>
                                                            <td>Agent Mobile Number</td>
                                                            <td>Gst Payment</td>
                                                            <td>Other Payment</td>
                                                            <td>Address</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {userInfo.paty_list && Object.entries(userInfo.paty_list).map(([key, party]) => (
                                                            <tr>
                                                                <td>{party.companyName}</td>
                                                                <td>{party.gstNumber}</td>
                                                                <td>{party.partyName}</td>
                                                                <td>{party.mobileNumber}</td>
                                                                <td>{party.agentName}</td>
                                                                <td>{party.agentMobileNumber}</td>
                                                                <td>{party.gstPayment}</td>
                                                                <td>{party.otherPayment}</td>
                                                                <td>{party.address}</td>
                                                            </tr>
                                                        ))}
                                                        {!userInfo.paty_list || userInfo.paty_list.length <= 0 && <tr>
                                                            <td colSpan={9} className="text-center">No any data</td>
                                                        </tr> }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(UserList)
