import React, { useState } from 'react';
import { withRouter, useHistory } from "react-router-dom";
import { post } from '../../helpers/api_helper';
import { NOTIFICATION_SEND } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const NotificationForm = props => {
    let history = useHistory();

    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({'title' : '','description' : ''});
    const [, forceUpdate] = useState();



    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        SetButtonDisabled(true);

        var res_data = await post(NOTIFICATION_SEND, form_inputs, {});
        if (res_data.status) {
            toast(res_data.message);
            history.push("/notification");
        } else {
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }


    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Admin | Notification Send</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-6'>
                    <div className='card'>
                        <div class="card-header">
                            <h6 className='m-0'>Notification Send</h6>
                        </div>
                        <div className='card-body'>
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className="form-group mb-3">
                                    <label className="form-label text-capitalize">Title</label>
                                    <input name="title" value={form_inputs.title} onChange={handleInputChange} className="form-control" type="text" />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label text-capitalize">Message</label>
                                    <textarea name="message" value={form_inputs.message} onChange={handleInputChange} className="form-control" rows={4} />
                                </div>
                               
                                <div className="text-center mt-4">
                                    <button disabled={ButtonDisabled} type="submit" className='btn btn-primary pt-1 pb-1 pl-3 pr-3 c-btn-primary'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Send</button>
                                    <Link to="/notification" className='btn btn-danger c-btn-danger ml-3 pt-1 pb-1 pl-3 pr-3'>Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(NotificationForm)
