import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom"
import {del} from '../../helpers/api_helper';
import { POST_USER_FORM_LIST, DELETE_USER_FORM} from '../../helpers/url_helper';
import {Helmet} from "react-helmet";
import { getToken,uct_local } from '../../helpers/Helper';
import { toast } from 'react-toastify';
import ReactDOM from 'react-dom';

import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";


const UserList = props => {

    const [Id, SetId] = useState(0);

    useEffect(() => {
        get_list();
    }, [])

   // get list
   const get_list = () => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": POST_USER_FORM_LIST,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [] }, { "targets": 0, "visible": false }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                        "error": function (xhr, textStatus, errorThrown) {
                            if (xhr.status === 401) {
                                window.location.href = '/logout';
                            }
                        }
                    });
                },
                "columns": [
                    { "data": "id" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {uct_local(data.createdAt,'DD/MM/YYYY')}
                            </>, td)
                        }
                    },
                    { "data": "companyName" },
                    { "data": "name" },
                    { "data": "mobileNumber" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {(data.accessAddParty == 'Yes'? 'Yes' : '')}
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                <button class="btn btn-sm btn-danger pt-0 pb-0 pl-1 pr-1  mr-2" onClick={() => { SetId(data.id); }} data-toggle="modal" data-target="#deleteModal" ><i class="fa fa-trash-o" style={{'font-size': '12px'}} aria-hidden="true"></i></button>
                            </>, td)
                        }
                    },
                ]
            });
        });
    }

    // remove user role
    const remove_row = async () => {
        var res_data = await del(DELETE_USER_FORM + "?id=" + Id);
        if (res_data.status) {
            toast(res_data.message);
            get_list();
        } else {
            toast.error(res_data.message);
        }
    }


    return (
        <>  
            <Helmet>
                <meta charSet="utf-8" />
                <title>Admin | Users List</title>
            </Helmet>
            
            <div className='row mt-3'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h6 className='mt-2 mb-0'>Users List</h6>
                                </div>
                                <div className="col-sm-6 text-right">
                                    {/* <Link to="/notification/add" className="btn btn-sm btn-primary pt-1 pb-1 pl-3 pr-3 c-btn-primary text-white">+ Add</Link> */}
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className="mt-2 mb-5">
                                <table id="dataTableExample" className="table table-striped ">
                                    <thead>
                                        <tr>
                                            <td>Id</td>
                                            <td>Date</td>
                                            <td>Company Name</td>
                                            <td>Name</td>
                                            <td>Mobile Number</td>
                                            <td>Add Party</td>
                                            <td>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Confirm Delete</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to delete this record?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-sm btn-dnager c-btn-danger text-white" data-dismiss="modal">No</button>
                                <button onClick={remove_row.bind(this)} data-dismiss="modal" className="btn btn-sm btn-primary c-btn-primary">Yes</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>    
    );
}
export default withRouter(UserList)
