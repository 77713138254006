import React, { useState,useEffect } from 'react';

// component
import Header from '../component/header';
import SideNavbar from '../component/side_navbar';

function Default({ children }) {
    window.scrollTo(0, 0);
    const [MenuHide, setMenuHide] = useState('');

    useEffect(() => {
        if (window.innerWidth < 768) {
            setMenuHide('hide_menu');
        }
        window.addEventListener('resize', () => {
            if (window.innerWidth < 768) {
                setMenuHide('hide_menu');
            } else {
                setMenuHide('');
            }
        })
    }, []) 

    const handleMenu = () => {
        if(MenuHide){
            setMenuHide('');
        }else{
            setMenuHide('hide_menu');
        }
    }
    
    return (
        <>  
            <div className={MenuHide}>
                <Header onChangeHandleMenu={handleMenu} />
                <div className='main_body'>
                    <SideNavbar/>
                    <div className='web_body pt-3 pb-3 pl-20 pr-20'>
                        <div className='container-fluid'>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Default;
