import React, { useState, useRef } from 'react';
import PropTypes from "prop-types"
import { useHistory, withRouter, Link } from "react-router-dom";
import { post } from '../../helpers/api_helper';
import { POST_ADD_USER } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

const Login = props => {
    let history = useHistory();

    const simpleValidator = useRef(new SimpleReactValidator());

    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [IsSelect, SetIsSelect] = useState(false);
    const [form_inputs, setInputs] = useState({
        companyName: '', userInfo: [
            { name: '', mobileNumber: '', accessAddParty: false },
            { name: '', mobileNumber: '', accessAddParty: false },
            { name: '', mobileNumber: '', accessAddParty: false },
            { name: '', mobileNumber: '', accessAddParty: false },
        ]
    });
    const [, forceUpdate] = useState();

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_ADD_USER, form_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
                setInputs({companyName: '', userInfo: [
                    { name: '', mobileNumber: '', accessAddParty: false },
                    { name: '', mobileNumber: '', accessAddParty: false },
                    { name: '', mobileNumber: '', accessAddParty: false },
                    { name: '', mobileNumber: '', accessAddParty: false },
                ]})
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }

    const handleInputChangeMultiple = (index, event) => {
        var name = event.target.name;
        if (name === 'accessAddParty') {
            form_inputs.userInfo[index][name] = event.target.checked;
            if(event.target.checked){
                SetIsSelect(true);
            }else{
                SetIsSelect(false);
            }
        } else {
            form_inputs.userInfo[index][name] = event.target.value;
        }
        setInputs(inputs => ({ ...form_inputs, ['userInfo']: form_inputs.userInfo }));
    }

    const removeRow = (index, type) => {
        var data = form_inputs.userInfo;
        data.splice(index, 1);
        setInputs(inputs => ({ ...form_inputs, ['userInfo']: data }));
    }

    const addRow = (type) => {
        var data = (form_inputs.userInfo) ? form_inputs.userInfo : [];
        data.push({ name: '', mobileNumber: '', accessAddParty: false });
        setInputs(inputs => ({ ...form_inputs, [type]: data }));
    }

    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-4 col-lg-3'></div>
                    <div className='col-sm-12 col-lg-6 mt-5'>
                        <h4 className='mb-4 text-center mb-4'>CERAMIC BE AWARE</h4>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label>Company Name</label>
                                <input name="companyName" value={form_inputs.companyName} onChange={handleInputChange} className="form-control" placeholder="Enter company name" type="text" required/>
                                {simpleValidator.current.message('company name', form_inputs.companyName, 'required')}
                            </div>
                            {form_inputs.userInfo && Object.entries(form_inputs.userInfo).map(([key, user]) => (
                                <div className='form-group' key={key}>
                                    <div className='row'>
                                        <div className='col-sm-4 mb-2'>
                                            <input name="name" onChange={handleInputChangeMultiple.bind(this, key)} value={user.name} type="text" className="form-control" placeholder="Enter Name" required={(key== 0)? true : false}/>
                                        </div>
                                        <div className='col-sm-4 mb-2'>
                                            <input name="mobileNumber" onChange={handleInputChangeMultiple.bind(this, key)} value={user.mobileNumber} maxLength={10} type="number" className="form-control" placeholder="Enter mobile number" required={(key== 0)? true : false} />
                                        </div>
                                        <div className='col-sm-4'>
                                            {(!IsSelect || user.accessAddParty) && <label>
                                                <input type='checkbox' name='accessAddParty' checked={user.accessAddParty} onChange={handleInputChangeMultiple.bind(this, key)} className="mt-3 mr-2 mt-1" />
                                                Add Party
                                            </label>}
                                        </div>
                                        {/* <div className='col-sm-1'>
                                            <button type="button" onClick={removeRow.bind(this, key)} className="btn btn-sm btn-outline-dark hover-none mt-2"><i className="fa fa-trash-o" aria-hidden="true"></i></button>
                                        </div> */}
                                    </div>
                                </div>
                            ))}
                            {/* {form_inputs.userInfo?.length < 5 && <div className='form-group' >
                                <div className='row'>
                                    <div className='col-sm-11 mb-2'>
                                    </div>
                                    <div className='col-sm-1'>
                                        <button type='button' onClick={addRow.bind(this, 'userInfo')} className='btn btn-sm btn-outline-dark hover-none mt-2'><i className="fa fa-plus-square-o" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </div>} */}


                            <div className="form-group mt-4 text-center">
                                <button disabled={ButtonDisabled} type="submit" class="btn btn-primary c-btn-primary"> {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Submit </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(Login)
Login.propTypes = {
    history: PropTypes.object,
}
