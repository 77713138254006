import React from 'react';
import { NavLink } from "react-router-dom";

import FeatherIcon from "feather-icons-react";
export default function Header() {

    return (
        <div className='left_nav_bar'>
            <ul className='sidebar-menu'>
                <li>
                    <NavLink exact to="/" className="has-arrow">
                        <FeatherIcon icon="home" width="22" />
                        <span>Dashboard</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to="/users" className="has-arrow">
                        <FeatherIcon icon="users" width="22" />
                        <span>User</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to="/party" className="has-arrow">
                        <FeatherIcon icon="users" width="22" />
                        <span>Party</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to="/notification" className="has-arrow">
                        <FeatherIcon icon="bell" width="20" className="mt-1" />
                        <span>Notification</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to="/user-form" className="has-arrow">
                        <FeatherIcon icon="users" width="20" className="mt-1" />
                        <span>User Form</span>
                    </NavLink>
                </li>


                {/* <li>
                    <NavLink to="/categories" >
                        <i class="fa fa-clone mr-2" aria-hidden="true" style={{ 'fontSize': '19px', 'marginTop': '5px' }}></i>
                        <span>Category</span>
                    </NavLink>
                </li>
                {IsModuleAccess('product') && <li>
                    <NavLink exact to="/products" className="has-arrow">
                        <i class="fa fa-cubes mr-1" aria-hidden="true" style={{ 'fontSize': '19px', 'marginTop': '5px' }}></i>
                        <span>Product</span>
                    </NavLink>
                </li>}
                {IsModuleAccess('gift_vouchers') && <li>
                    <NavLink exact to="/vouchers" className="has-arrow">
                        <i class="fa fa-gift mr-2" aria-hidden="true" style={{ 'fontSize': '19px', 'marginTop': '5px' }}></i>
                        <span>Gift Vouchers</span>
                    </NavLink>
                </li>}
                {IsModuleAccess('user_gift_vouchers') && <li>
                    <NavLink exact to="/user-vouchers" className="has-arrow">
                        <i class="fa fa-gift mr-2" aria-hidden="true" style={{ 'fontSize': '19px', 'marginTop': '5px' }}></i>
                        <span>User Gift Vouchers</span>
                    </NavLink>
                </li>}
                {IsModuleAccess('order') && <li>
                    <NavLink exact to="/order" className="has-arrow">
                        <i class="fa fa-shopping-cart mr-2" aria-hidden="true" style={{ 'fontSize': '19px', 'marginTop': '5px' }}></i>
                        <span>Order</span>
                    </NavLink>
                </li>}
                {IsModuleAccess('table_booking') && <li>
                    <NavLink exact to="/table-booking" className="has-arrow">
                        <i class="fa fa-book mr-2" aria-hidden="true" style={{ 'fontSize': '19px', 'marginTop': '5px' }}></i>
                        <span>Table Booking</span>
                    </NavLink>
                </li>}
                {IsModuleAccess('bottomless_brunch_booking') && <li>
                    <NavLink exact to="/bottomless-brunch" className="has-arrow">
                        <i class="fa fa-book mr-2" aria-hidden="true" style={{ 'fontSize': '19px', 'marginTop': '5px' }}></i>
                        <span>Bottomless Brunch</span>
                    </NavLink>
                </li>}
                {IsModuleAccess('funcation_booking') && <li>
                    <NavLink exact to="/functions-booking" className="has-arrow">
                        <i class="fa fa-book mr-2" aria-hidden="true" style={{ 'fontSize': '19px', 'marginTop': '5px' }}></i>
                        <span>Funcation Enquiry</span>
                    </NavLink>
                </li>}
                {(IsModuleAccess('setting') || IsModuleAccess('banner')) && <li>
                    <a href="#SettingSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle" >
                        <FeatherIcon icon="settings" width="22" />
                        <span style={{ 'marginTop': '2px' }}>Setting</span>
                    </a>
                    <ul class="collapse list-unstyled" id="SettingSubmenu">
                        <li>
                            {IsModuleAccess('banner') && <NavLink to="/banner">Banner</NavLink>}
                            {IsModuleAccess('setting') && <NavLink to="/setting">Setting</NavLink>}
                        </li>
                    </ul>
                </li>} */}


            </ul>
        </div>
    );
}