const moment = require("moment");

const getToken = () => {
    return localStorage.getItem('token') || null;
}

const getUserDetail = () => {
    return JSON.parse(localStorage.getItem('user_detail'));
}

const getUserId = () => {
    return (localStorage.getItem('user_detail')) ? JSON.parse(localStorage.getItem('user_detail'))['user_id'] : 0;
}

const getUserType = () => {
    if (localStorage.getItem('user_detail')) {
        return JSON.parse(localStorage.getItem('user_detail'))['u_type'];
    } else {
        return 0;
    }
}

const removeUserSession = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user_detail');
    localStorage.clear();
}

const setUserSession = (token, user) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user_detail', JSON.stringify(user));
}

const is_login = () => {
    if (localStorage.getItem('token')) {
        return true;
    } else {
        return false;
    }
}

const amountFormat = (amount) => {
    return parseFloat(amount).toFixed(2);
}

const IsModuleAccess = (modem_name) => {
    return true;
    var mode = JSON.parse(JSON.parse(localStorage.getItem('user_detail'))['role_access']);
    if (mode[modem_name] && mode[modem_name].module_access === 1) {
        return true;
    } else {
        return false;
    }
}
const img_view = (img, width = '', height = '', auto = true) => {
    return process.env.REACT_APP_IMG_URL + 'uploads/' + img
    if (auto) {
        return process.env.REACT_APP_IMG_URL + 'img?img=' + img + '&w=' + width + '&h=' + height
    } else {
        return process.env.REACT_APP_IMG_URL + 'uploads/' + img
    }
}
const Date_format = (date) => {
    var date = new Date(date),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
}

const generate_password = (length) => {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var reference_code = '';
    for (var i = 0; i < length; i++) {
        reference_code += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }

    return reference_code;
}

const uct_local = (data_time,format ="DD/MM/YYYY HH:mm:ss") => {
    if(data_time){
        return moment.utc(data_time).local().format(format);
    }else{
        return '';
    }
}

export { uct_local, generate_password, Date_format, img_view, amountFormat, getUserType, getToken, removeUserSession, setUserSession, is_login, getUserDetail, getUserId, IsModuleAccess };