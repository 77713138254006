import React from 'react';
import { Switch } from 'react-router-dom';
import AppRoute from './layouts/App_route';
import UserRoute from './layouts/UserRoute';

import { BrowserRouter } from 'react-router-dom';

import Default from './layouts/Default';
import Full from './layouts/Full';

// pages
import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Auth/Login';
import Logout from "./pages/Auth/Logout";
import ForgetPassword from "./pages/Auth/ForgetPassword";
import ChangePassword from "./pages/Auth/ChangePassword";
import Form from "./pages/Auth/form";

//users
import UsersList from "./pages/Users/UsersList";
import UserForm from "./pages/Users/UserForm";

//paty
import PartyList from "./pages/Party/PartyList";

//notification
import NotificationList from "./pages/Notification/NotificationList";
import NotificationForm from "./pages/Notification/NotificationForm";

import UserFormList from "./pages/UserForm/UserFormList";


// setting
import Setting from "./pages/Setting/Setting";
import EmailSetting from "./pages/Setting/EmailSetting";
import ApiLogSetting from "./pages/Setting/ApiLogSetting";


function App() {
    return (
        <>
        <BrowserRouter>
            <Switch>
                <AppRoute exact path="/login" component={Login} layout={Full} />
                <AppRoute exact path="/logout" component={Logout} layout={Full} />
                <AppRoute exact path="/forgot-password" component={ForgetPassword} layout={Full} />
                <AppRoute exact path="/form" component={Form} layout={Full} />
                
                <UserRoute exact path="/change-password" component={ChangePassword} layout={Default} />

                
                {/* user */}
                <UserRoute exact path="/users" component={UsersList} layout={Default} />
                <UserRoute exact path="/users/edit/:id" component={UserForm} layout={Default} />

                {/* party */}
                <UserRoute exact path="/party" component={PartyList} layout={Default} />

                <UserRoute exact path="/notification" component={NotificationList} layout={Default} />
                <UserRoute exact path="/notification/add" component={NotificationForm} layout={Default} />

                <UserRoute exact path="/user-form" component={UserFormList} layout={Default} />

               

                {/* Setting */}
                <UserRoute exact path="/setting" component={Setting} layout={Default} />
                <UserRoute exact path="/setting/email" component={EmailSetting} layout={Default} />
                <UserRoute exact path="/setting/api-log" component={ApiLogSetting} layout={Default} />
                
                
                <UserRoute exact path="/" component={Dashboard} layout={Default} />
            </Switch>
        </BrowserRouter> 
    </>
  );
}

export default App;
