import React, { useRef, useState } from 'react';
import { withRouter, useHistory } from "react-router-dom";
import { post } from '../../helpers/api_helper';
import { POST_ADD_EDIT_USERS } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import SimpleReactValidator from 'simple-react-validator';

const NotificationForm = props => {
    let history = useHistory();


    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({ 'userId': props.match.params.id, 'password': '', 'c_password': '' });

    const simpleValidator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();



    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            form_inputs.userId = props.match.params.id;
            var res_data = await post(POST_ADD_EDIT_USERS, form_inputs);
            if (res_data.status) {
                toast(res_data.message);
                history.push("/users");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }


    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Admin | Change Password</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-6'>
                    <div className='card'>
                        <div class="card-header">
                            <h6 className='m-0'>Change Password</h6>
                        </div>
                        <div className='card-body'>
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className="form-group mb-3">
                                    <label className="form-label">Enter New Password</label>
                                    <input name="password" value={form_inputs.password} onChange={handleInputChange} className="form-control" type='password' />
                                    {simpleValidator.current.message('password', form_inputs.password, 'required')}
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label">Confirm Password</label>
                                    <input name="c_password" value={form_inputs.c_password} onChange={handleInputChange} className="form-control" type='password' />
                                    {form_inputs.password && simpleValidator.current.message('confirm password', form_inputs.c_password, `required|in:${form_inputs.password}`, { messages: { in: `Passwords don't match.` } })}
                                </div>
                                <div className="text-center mt-4">
                                    <button disabled={ButtonDisabled} type="submit" className='btn btn-primary pt-1 pb-1 pl-3 pr-3 c-btn-primary'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Send</button>
                                    <Link to="/users" className='btn btn-danger c-btn-danger ml-3 pt-1 pb-1 pl-3 pr-3'>Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(NotificationForm)
