
// auth
export const POST_LOGIN = "/auth/login"
export const POST_FORGOT_PASSWORD = "/auth/forgot-password"
export const POST_RESET_PASSWORD = "/auth/reset-password"
export const POST_ADD_USER = "/auth/add-user"

export const GET_DASHBOARD_DATA = "/dashboard/dashboard"
export const POST_CHANGE_PASSWORD = "/dashboard/change-password"

//setting
export const GET_ALL_SETTING = "/setting/get-all-setting"
export const POST_UPDATE_SETTING = "/setting/update-setting"
export const POST_UPDATE_SETTING_MENU = "setting/update-menu"

//Users
export const POST_USERS_LIST = process.env.REACT_APP_API_URL+"users/get-all-user"
export const DELETE_USERS = "/users/delete"
export const POST_UPDATE_USERS_STATUS = "/users/update-status"
export const POST_ADD_EDIT_USERS = "/users/add-edit"
export const POST_GET_USERS_DETAIL = "/users/detail"

// party
export const POST_PARTY_LIST = process.env.REACT_APP_API_URL+"party/get-all-party"
export const DELETE_PARTY = "/party/delete"

//notification
export const POST_NOTIFICATION_LIST = process.env.REACT_APP_API_URL+"notification/get-all-notification"
export const DELETE_NOTIFICATION = "/notification/delete"
export const NOTIFICATION_SEND = "/notification/add-notification"

//userform
export const POST_USER_FORM_LIST = process.env.REACT_APP_API_URL+"user_form/get-all-user"
export const DELETE_USER_FORM = "/user_form/delete"
